import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2 className="lose-margin-bottom">
          Diese Seite steht aktuell im Aufbau.
        </h2>
        <h4 className="lose-margin-top">
          Bitte besuchen Sie bis zur Freischaltung die aktuelle Seite.
        </h4>
        <a
          className="App-link"
          href="https://musik-und-showband.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          Musik- und Showband
        </a>
      </header>
    </div>
  );
}

export default App;
